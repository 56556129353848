const getPalette = (theme) => {
  return {
    type: "light",
    primary: { main: "#4A2F92", hover: "#EDB236" },
    secondary: { main: "#4A2F92", hover: "#EDB236" },
    accent: "#4A2F92",
    info: {
      main: "#4A2F92",
    },
    error: {
      main: "#FF0040",
    },
    white: {
      main: "#FFF",
    },
  };
};

export default getPalette;
