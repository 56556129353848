export const menuImages = [
  {
    src: "https://my-orders.ams3.digitaloceanspaces.com/cinnamon/menu/8.jpg",
    alt: "8",
  },
  {
    src: "https://my-orders.ams3.digitaloceanspaces.com/cinnamon/menu/4.jpg",
    alt: "4",
  },
  {
    src: "https://my-orders.ams3.digitaloceanspaces.com/cinnamon/menu/5.jpg",
    alt: "5",
  },
  {
    src: "https://my-orders.ams3.digitaloceanspaces.com/cinnamon/menu/7.jpg",
    alt: "7",
  },
  {
    src: "https://my-orders.ams3.digitaloceanspaces.com/cinnamon/menu/6.jpg",
    alt: "6",
  },
  {
    src: "https://my-orders.ams3.digitaloceanspaces.com/cinnamon/menu/2.jpg",
    alt: "2",
  },
  {
    src: "https://my-orders.ams3.digitaloceanspaces.com/cinnamon/menu/3.jpg",
    alt: "3",
  },
  {
    src: "https://my-orders.ams3.digitaloceanspaces.com/cinnamon/menu/1.jpg",
    alt: "1",
  },
];

export const restaurantImages = [
  {
    src: "https://my-orders.ams3.digitaloceanspaces.com/cinnamon/restaurant/8.jpg",
    alt: "8",
  },
  {
    src: "https://my-orders.ams3.digitaloceanspaces.com/cinnamon/restaurant/9.jpg",
    alt: "9",
  },
  {
    src: "https://my-orders.ams3.digitaloceanspaces.com/cinnamon/restaurant/12.jpg",
    alt: "12",
  },
  {
    src: "https://my-orders.ams3.digitaloceanspaces.com/cinnamon/restaurant/11.jpg",
    alt: "11",
  },
  {
    src: "https://my-orders.ams3.digitaloceanspaces.com/cinnamon/restaurant/10.jpg",
    alt: "10",
  },
  {
    src: "https://my-orders.ams3.digitaloceanspaces.com/cinnamon/restaurant/4.jpg",
    alt: "4",
  },
  {
    src: "https://my-orders.ams3.digitaloceanspaces.com/cinnamon/restaurant/5.jpg",
    alt: "5",
  },
  {
    src: "https://my-orders.ams3.digitaloceanspaces.com/cinnamon/restaurant/7.jpg",
    alt: "7",
  },
  {
    src: "https://my-orders.ams3.digitaloceanspaces.com/cinnamon/restaurant/6.jpg",
    alt: "6",
  },
  {
    src: "https://my-orders.ams3.digitaloceanspaces.com/cinnamon/restaurant/2.jpg",
    alt: "2",
  },
  {
    src: "https://my-orders.ams3.digitaloceanspaces.com/cinnamon/restaurant/3.jpg",
    alt: "3",
  },
  {
    src: "https://my-orders.ams3.digitaloceanspaces.com/cinnamon/restaurant/1.jpg",
    alt: "1",
  },
];
