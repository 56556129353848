import React from "react";
import Typography from "@mui/material/Typography";
import { menuImages, restaurantImages } from "./gallery";
import { getSwiperImages } from "../../utils/swiper";

import cssStyles from "./About.module.scss";

export function getAboutText() {
  return (
    <div className={cssStyles.about}>
      <Typography variant="h2" gutterBottom>
        Banquet Night
      </Typography>

      <Typography variant="body1" paragraph>
        Every Tuesday- Dine in only from 5.30p.m to 11.00p.m. Choose any one
        dish from each section Popadoms & Chutneys STARTER, MAIN COURSE, SIDE
        DISH AND RICE or BREAD £16.95 per person (King Prawn Dishes Extra £4.00.
        Drinks are not included in this offer. This offer is not valid with any
        others promotions. The management reserves the right to withdraw this
        offer at any time.)
      </Typography>

      <Typography variant="h2" gutterBottom>
        Sunday Buffet
      </Typography>

      <Typography variant="body1" paragraph>
        Non - Vegetarian & Vegetarian dishes. Available from 12noon to 2.30p.m.
        ADULT: £14.95, CHILDREN (under 12 years): £9.95
      </Typography>
    </div>
  );
}

export function getAdditionalAboutText() {
  return null;
}

export function getAccolades() {
  return [];
}

export function getGalleryMenuImages() {
  return getSwiperImages(menuImages);
}

export function getGalleryRestaurantImages() {
  return getSwiperImages(restaurantImages);
}
